import banner3 from "../img/banner3.jpg"
import { Link } from "react-router-dom";
const Notfound = () => {
    return (
        <div className="banner d-flex align-items-center" style={{ backgroundImage: `url(${banner3})` }}>
            <div className="bg-custom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="banner-area text-center pt-4 pb-4">
                                <p>Välkommna till en plats i solen</p>
                                <h2 className="mt-2 mb-4 banner-title"><strong> Din mäklare i Andalucien</strong> </h2>
                               
                                    <h3>Tyvärr hittades in sidan :(</h3>
                                    <p>Som tur är har vi massor av andra intressanta sidor att kolla in, letar du tex. efter <Link to="/estepona">lägenheter i estepona</Link> eller att <Link to="/kopa-hus-i-malaga">köpa hus i Malaga</Link>så har du kommit rätt. </p>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notfound