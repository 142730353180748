// import { useEffect, useState } from "react"
import banner1 from "../img/nerja.webp"
import banner2 from "../img/hav3.webp"
import banner3 from "../img/IMG_20210526_155920.webp"
import banner4 from "../img/cordoba.webp"
// import { Link } from "react-router-dom";

const Banner = ({page}) => {
    console.log(page)
    let banner = banner1
    if (page === "estepona") {
        banner = banner2
    }
    else if (page === "contact") {
        banner = banner3
    }
    else if (page === "about") {
        banner = banner4
    }
    else if (page === "malaga") {
        banner = banner1
    }
    else if (page === "notfound") {
        banner = banner3
    }
    else {
        banner = banner1
    }
    // const [search, setSearch] = useState();
    // const [find, setFind] = useState([]);
    // const [word, setWord] = useState("");
    // useEffect(() => {
    //     setSearch(["a","b","test", "mb"])
    // }, [])
    // // const findSearch = (e) => {
    //     setWord(e.target.value)
    //     const filteredCountries = search.filter(item => item.indexOf(e.target.value) > -1 ? item : null);
    //     e.target.value.length === 0 ? setFind([]) : setFind(filteredCountries);
    // }
    // const findResult = () => {
    //     if (find.length === 0 && word.length > 0) {
    //         return <div className="find-search">Not Found</div>
    //     }
    //     if (find.length > 0) {
    //         return <div className="find-search">
    //             {
    //                 find.map(item => {
    //                     return <Link key={item} to="#">{item}</Link>
    //                 })
    //             }
    //         </div>
    //     }
    // }
    return (
        
        <div className="banner d-flex align-items-center" style={{ backgroundImage: `url(${banner})` }}>
            <div className="bg-custom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="banner-area text-center pt-4 pb-4">
                                <p>Välkommna till en plats i solen</p>
                                <h3 className="mt-2 mb-4 banner-title"><strong> Din mäklare i Andalusien</strong> </h3>
                                {/* <div className="search-area">
                                    <input value={word} onChange={(e) => findSearch(e)} type="text" className="inp-search" placeholder="Search" />
                                    <button className="btn-search m-2">Search All</button>
                                </div>
                                {findResult()} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;