import FlatList from "./FlatList"
import Banner from "./Banner"
import React from "react"
import TeamList from "./TeamList"
import Title from "./Title"
import References from "./References"
import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList"
import { useState, useEffect } from "react";
import * as contentful from "contentful"



const Estepona=()=>{
    // State to store the fetched data
    const [data, setData] = useState(null);

  // State to track if the data is still loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from API on component mount
        const fetchData = async () => {
          try {
            const client = contentful.createClient({
                space: 'cx70k9zsq5af',
                environment: 'master', // defaults to 'master' if not set
                accessToken: 'F2kMyptLDLJVSZnnmNilbGhjaos8o6eY12pIHBiXlzc'
              })
              
            //   client.getEntry('1D9Vo6dCTArZxuX0e6GVlq')
            //     .then((entry) => console.log(entry))
            //     .catch(console.error)
            
           await client.withoutUnresolvableLinks.getEntries()
                .then(
                    (entries) => {
                        console.log(entries)
                        // Store the data in state
                        setData(entries.items)
                    }
                )
                .catch(console.error)
            setLoading(false); // Set loading to false once data is fetched
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures the effect runs only once

    return (
        <React.Fragment>
            <Banner page={"estepona"}/>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Estepona"} description={"Solkustens trädgård"} size= {2}/>
                            <p>Estepona som även kallas Costad del Sol’s trädgård har blivit väldigt populärt bland svenskar på senare tid. Det är en mysig, genuint spansk stad som ligger cirka 70 kilometer från Malaga och har cirka 70 000 invånare. 
                            Den långa sandtranden La Rada går genom hela staden där kan man njuta av sol och bad, promenera, jogga, träna i något utav utomhus gymmen eller ta något att äta eller dricka på någon utav alla Chiringuitos. Det finns även en del lekplatser där de yngre kan roa sig.</p>
                            <p>I hamnen kan du se fiskebåtar, handla på fiskauktion vissa dagar i veckan eller bara sitta och njuta av en god drink eller mat på någon utav restaurangerna. Här kan du avnjuta frukost, lunch och middag. I hamnen är det även marknad varje söndag där man säljer kläder, smycken, handväskor, leksaker etc. 
                            I gamla stan kan man promenera längs de smala gatorna med vita hus på sidorna här hänger blommor på väggarna längs de flesta gatorna ofta i samma typ av kruka längs hela gatan. Här hittar man mycket restauranger, barer, caféer och shopping.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-th"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/solnedgang.webp" alt="solnedgang" className="w-75 rounded mx-auto mt-2rounded mx-auto mt-4" />
                        </div>
                        <div className="col-lg-6">
                            <img src="/img/castillodesanluis.webp" alt="castillodesanluis" className="w-75 rounded mx-auto mt-4" />
                         </div>
                    </div>
                </div>
            </section>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                             <Title title={"Lägenhet i Estepona"} size= {3}/>
                            <p>Lägenhet i Estepona kan man hitta i gamla stan med omnejd om man vill bo centralt. Här finns även hus för den som är intresserad av större boende. I hamnen finns också mycket lägenheter och många utav dom har utsikt över havet. </p>
                            <p>Ett område med lägenheter i Estepona som blivit populärt bland svenskar är Las Mesas som ligger lite uppe på en höjd. Här i området är det marknad varje onsdag med försäljning utav t ex frukt, grönsaker, blommor, kryddor, kläder, handväskor och här ligger
                                även ett populärt gym och padelbanor. Nära Las Mesas finns ett område som heter Seghers där det finns större hus med trädgårdar. </p>
                            <p>En bit utanför Estepona stad finns många olika områden med både hus och lägenheter, områdena brukar vara ha någon typ av inhängnad med egen trädgård och pool. Vissa urbanisationer ligger intill en golfbana för den som är intresserad av att spela golf. 
                            </p>
                            <p>Att ha en lägenhet i Estepona innebär ett liv i en underbar småstad med sol och värme nästan hela året om. Här känns det nästan som att man är på semester hela tiden med det stora utbudet av sol och bad, restauranger, aktiviteter i berg och hav. När skola och 
                                jobb är slut för dagen har man möjlighet att åka och bada eller ta en tur till lekparken eller göra någon annan aktivitet och det är fortfarande sol och ljust året om, ett härligt sätt att leva!
                                </p>
                        </div>
                    </div>
                </div>    
            </section>
            {loading ? (
                <p>Loading data...</p>
            ) : (
                <FlatList properties={data} />
            )}
            <BestFlatList/>
            <Subscribe/>
            <TeamList/>
            <References/>
        </React.Fragment>
    )
}

export default Estepona;